.search-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    padding: 32px 0 0;
    min-height: 100vh;
    padding-bottom: 120px;
}
.search-page__content{
    width: 100%;
    max-width: 1280px;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.search-page__title {
    text-align: left;
    width: 100%;
    word-break: break-all;
    color: var(--neutral-active);
}

.search-page__grid {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 60px;
    column-gap: 32px;

    grid-template-rows: max-content;
    grid-auto-rows: max-content;
}
