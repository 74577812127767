.oreder-error-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px
}
/* 
.oreder-error-popup__icon {
    width: 80px;
    height: 80px;
}

.oreder-error-popup__icon_fill {
    fill: var(--title-color);
} */

.oreder-error-popup__text {
    font-size: var(--text-size);
    text-align: center;
    color: var(--neutral-active);
    font-weight: var(--h3-weight);
    line-height: 140%;
}


.oreder-error-popup__subtext {
    font-size: var(--text-size);
    text-align: center;
    color: var(--neutral-active);
    font-weight: var(--text-weight);
    line-height: 140%;
}