body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--website_background-background);
}




input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px var(--website_background-background) inset !important;
  /* можно изменить на любой вариант цвета */
  -webkit-text-fill-color: var(--neutral-active) !important;
  background-color: var(--website_background-background) !important;
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
}

h1{
  font-size: var(--h1-size);
  font-weight: var(--h1-weight);
}


h2{
  font-size: var(--h2-size);
  font-weight: var(--h2-weight);
}


h3{
  font-size: var(--h3-size);
  font-weight: var(--h3-weight);
}


h4{
  font-size: var(--h4-size);
  font-weight: var(--h4-weight);
}

p {
  font-size: var(--text-size);
  font-weight: var(--text-weight);
}



a {
  text-decoration: none;
  color: var(--neutral-active);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}