.personal-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 700px;
    gap: 64px;
}

.personal-info__inputs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.personal-info__inputs-title {
    color: var(--neutral-active);

}

.personal-info__no-delivery-title {
    color: var(--neutral-active);
}

.personal-info__two-inputs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 32px;
}

.personal-info__input {
    border-bottom: 1px solid var(--secondary-active);
    box-sizing: border-box;
    padding: 12px 12px;
    display: flex;
    height: 32px;
    /* margin-top: 0.88rem; */
    align-items: center;
    transition: all 0.2s linear;
}

.personal-info__select-input-box {
    width: 100%;
    display: grid;
    grid-template-rows: max-content;
    border: 1px solid var(--primary-active);
    border-radius: var(--initial_border_radius-value);
    overflow: hidden;
}

.personal-info__select-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 8px;
    box-sizing: border-box;
    height: 100%;
    background: var(--website_background-background);
    color: var(--neutral-active);
    transition: all 0.2s linear;

    font-size: var(--text-size);
    font-weight: var(--text-weight);
    /* box-shadow: -1px 0px 0px 0px var(--secondary-selection) inset; */
}

/* .personal-info__select-input:first-of-type {
    border-radius: var(--initial_border_radius-value) 0px 0px var(--initial_border_radius-value);
}

.personal-info__select-input:last-of-type {
    border-radius: 0px var(--initial_border_radius-value) var(--initial_border_radius-value) 0px;
    box-shadow: unset;
} */

.personal-info__select-input_selected {
    color: var(--website_background-background);
    background-color: var(--primary-active);
}

.personal-info__delivery-hint {
    text-align: center;

    width: 100%;
    margin: -24px 0 0;
}

.personal-info__checkbox {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-template-rows: max-content;
    column-gap: 12px;
}

.personal-info__checkbox-text {
    text-align: center;
}

.personal-info__checkbox-text-link {
    color: var(--neutral-active);
    text-decoration: underline;
}

.personal-info__checkbox-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    transition: all 0.2s linear;
    border: 1px solid var(--neutral-active);
    border-radius: calc(var(--initial_border_radius-value) / (2 * 2));
}

.personal-info__checkbox_selected .personal-info__checkbox-box {
    background-color: var(--secondary-active);
}

.personal-info__checkbox-box-icon {
    width: 10px;
    height: 10px;
}


.personal-info__checkbox-box-icon-stroke {
    stroke: transparent;
    transition: all 0.2s linear;
}

.personal-info__checkbox_selected .personal-info__checkbox-box-icon-stroke {
    stroke: var(--neutral-active);
}

.personal-info__address-input {
    display: flex;
    flex-direction: column;
}


@media (max-width: 1200px) {
    .personal-info {
        padding: 0;
    }
}

@media (max-width: 1050px) {
    .personal-info {
        /* margin: 16px 0 0; */
    }
}

@media (max-width: 700px) {
    .personal-info__two-inputs {
        column-gap: 16px;
    }

    .personal-info__inputs-container {
        gap: 16px;
    }

    .personal-info__delivery-hint {
        margin: 0;
    }
}

@media (max-width: 580px) {
    .personal-info__select-input-box {
        display: flex;
        flex-direction: column;

    }

    .personal-info__select-input {
        padding: 10px 8px;
        box-shadow: 0px -1px 0px 0px rgba(167, 144, 97, 0.20) inset;
    }

    /* .personal-info__select-input:first-of-type {
        border-radius: 16px 16px 0px 0px;
    }

    .personal-info__select-input:last-of-type {
        border-radius: 0px 0px 16px 16px;
    } */
}


@media (max-width: 500px) {
    .personal-info__two-inputs {
        grid-template-columns: 1fr;
        row-gap: 16px;
    }
}