.category-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;


}



.category-card__img {
    width: 100%;
    /* height: 200px; */
    object-fit: scale-down;

    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
    background-color: var(--neutral-background);
    aspect-ratio: 1 / 1;
}


.category-card__name {
    margin: 10px 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--neutral-active);
}


@media (max-width: 700px) {
    .category-card__name {
        font-size: 14px;
    }
}