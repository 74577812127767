.filter-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: var(--website_background-background);
    border-radius: calc(var(--initial_border_radius-value));
    padding: 0 12px;
}

.filter-popup__heading {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content;
    align-items: center;
    gap: 12px;
}

.filter-popup__title {
    color: var(--secondary-avtive);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    opacity: 0.7;
}

.filter-popup__icon {
    width: 24px;
    height: 24px;
    opacity: 0.7;
}

.filter-popup__icon-stroke {
    color: var(--secondary-avtive);
}

.filter-popup__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 20px 0 0;
}

.filter-popup__btns {
    margin: 20px 0 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 12px;
}

.filter-popup__btns_loading{
    pointer-events: none;
}

.filter-popup__btn {
    width: 100%;
    height: 100%;
    padding: 13px 0;
    box-sizing: border-box;
    border-radius: calc(var(--initial_border_radius-value) * (0.50 * 1));
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-popup__btn_apply {
    color: var(--website_background-background);
    background-color: var(--primary-active);
}

.filter-popup__btn_reset {
    color: var(--nutral-active);
    background-color: var(--secondary-background);
}