.goods {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 65px;
}

.no-goods {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin: 32px 0 0;
}

.no-goods__title {
    color: var(--neutral-active);
}

.no-goods__go-main {
    display: flex;
    width: fit-content;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;

    border-radius: 40px;
    background: rgba(167, 144, 97, 0.20);



    font-size: 14px;
    letter-spacing: -0.7px;
    font-weight: 700;
    background-color: var(--secondary-background);

    box-sizing: border-box;

}

.no-goods__go-main-text {
    color: var(--neutral-active);
}

.no-goods__empty-icon {
    width: 80px;
    height: 80px;
}

.no-goods__empty-icon-fill {
    fill: var(--secondary-active);
}

.goods__card {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0;
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: max-content;
    column-gap: 32px;
    width: 100%;
}

.goods__card-img {
    width: 100%;
    aspect-ratio: 170 / 240;
    border-radius: calc(var(--initial_border_radius-value) / (2* 1));
    object-fit: cover;
}

.goods__card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.goods__card-heading {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 24px;
}

.goods__card-title-and-controls {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.goods__card-title {}

.goods__controls {
    width: 108px;
    margin: 12px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content;
}

.goods__control {
    color: var(--website_background-background);
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: -1.12px;
    background: var(--secondary-active);

    padding: 10px;

    width: 100%;
    height: 100%;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: all 0.2s linear;


}

.goods__control {
    font-size: var(--text-size);
    font-weight: var(--text-weight);
    opacity: 0.6;
}

.goods__control:hover {
    opacity: 0.7;
}

.goods__control_type_left {
    border-radius: calc(var(--initial_border_radius-value) / (2* 1)) 0px 0px calc(var(--initial_border_radius-value) / (2* 1));
}

.goods__control_type_right {
    border-radius: 0rem 2.5rem 2.5rem 0rem;
    border-radius: 0px calc(var(--initial_border_radius-value) / (2* 1)) calc(var(--initial_border_radius-value) / (2* 1)) 0px;
}

.goods__control_inactive {
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
}

.goods__control-counter {

    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--selection-active);
}

.goods__control-counter-text {
    width: fit-content;
    text-align: center;

    color: var(--neutral-active);
}

.goods__params {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 280px;
    margin: 80px 0 0;
    gap: 12px;
}

.goods__param {
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 4px;
}

.goods__param-name {
    color: var(--neutral-active);
}

.goods__param-line {
    width: 100%;
    height: 1px;
    margin: auto 0 0;
    opacity: 0.2;
    background-color: var(--neutral-active);
}

.goods__param-value {
    text-align: right;
}


.goods__price-value {}


.goods__price-value-discounted {
    text-decoration: line-through;
}

.goods__card-img-box {
    position: relative;
}

.goods__price-discount {
    padding: 4px 8px;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 2));
    background-color: var(--semantic_minus-active);
    color: var(--website_background-background);
    position: absolute;
    left: 0;
    top: 0;
    height: fit-content;
    transform: rotate(-6.726deg);
}

.goods__price {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
}

.goods__not-in-stock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 24px 0 0;
    gap: 12px;
}

.goods__not-in-stock-title {
    color: var(--semantic_minus-active);
}

.goods__not-in-stock-hint{
    color: var(--semantic_minus-active);
    margin: 12px 0 0;
}

.goods__not-in-stock-btn {
    border-radius: calc(var(--initial_border_radius-value) * (1.5* 1));
    padding: 10px 14px;
    background-color: var(--semantic_minus-active);
}

.goods__not-in-stock-btn-text {
    color: var(--website_background-background);
}

.goods__control-counter-text_invalid{
    color: var(--semantic_minus-active);
}

@media (max-width: 1200px) {
    .goods__card {
        padding: 0;
    }
}

@media (max-width: 1000px) {
    .goods__card-title {
        font-size: var(--h3-size);
        line-height: 90%;
    }

    .goods__card {
        grid-template-columns: 200px 1fr;
    }

}

@media (max-width: 700px) {
    .goods {
        gap: 16px;
    }

    .goods__card {
        grid-template-columns: 90px 1fr;
        column-gap: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(130, 114, 82, 0.2);
    }

    .goods__card:last-of-type {
        border-bottom: unset;
    }

    .goods__card-title {}

    .goods__controls {
        margin: 12px 0 0;
    }

    .goods__params {
        margin: 24px 0 0;
        width: 100%;
        max-width: unset;
    }
}

@media (max-width: 500px) {
    .goods__card {
        grid-template-columns: 56px 1fr;
        column-gap: 16px;
    }

    .goods__card-title {
        font-size: var(--text-size);
    }

    .goods__controls {
        margin: 8px 0 0;
    }

    .goods__params {
        margin: 12px 0 0;
        width: 100%;
        max-width: unset;
    }
}