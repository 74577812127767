.image-with-background__img {
    /* width: 100%;
    height: fit-content;
    object-fit: scale-down; */
}

@keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .preloader {
    width: 100%;
    height: 100%;
    background-size: 200% 200%;
    background-image: linear-gradient(270deg, #f093fb, #f5576c, #fdf497, #f093fb);
    animation: gradientAnimation 3s ease infinite;
    display: flex;
    align-items: center;
    justify-content: center;
  }