.custom-categories {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 0;
    justify-content: center;
}

.custom-categories__item {
    padding: 0 7px;
    display: flex;
    flex-direction: column;
    max-width: 214px;
}

.custom-categories__img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

.custom-categories__title {
    margin: 10px 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--neutral-active);
}




@media (max-width: 700px) {
    .custom-categories__title {
        font-size: 14px;
    }
}

@media (max-width: 580px) {
    .custom-categories__item {
        max-width: 44vw;
        padding: 0 2px;
    }

    .custom-categories {
        gap: 12px 0;
    }
}

@media (max-width: 340px) {
    .custom-categories__item {
        max-width: 42vw;
    }

}