.select-filter {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 12px 0;
}

.select-filter__heading {
    display: flex;
    flex-direction: row;
    width: 100%;

}

.select-filter__name {
    color: var(--neutral-active);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.select-filter__values {
    display: flex;
    flex-direction: column;
    margin: 12px 0 0;
    gap: 12px;
    width: 100%;
    overflow: scroll;
    max-height: 124px;
}

.select-filter__value {
    padding: 0 18px 0 0;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-template-rows: max-content;
    column-gap: 10px;
    align-items: center;
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
}

.select-filter__value-checkbox {
    border-radius: calc(var(--initial_border_radius-value) * (0.50 * 2));
    width: 22px;
    height: 22px;
    background-color: var(--secondary-background);
    border: 1px solid var(--primary-active);
    transition: all 0.1s linear;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.select-filter__value-checkbox-icon{
    width: 12px;
    height: 12px;
}

.select-filter__value-checkbox-icon-stroke{
    stroke: var(--secondary-background);
    transition: all 0.1s linear;
}

.select-filter__value-checkbox_selected{
    background-color: var(--primary-active);
}


.select-filter__value-checkbox_selected .select-filter__value-checkbox-icon-stroke{
    stroke: var(--website_background-background);
}