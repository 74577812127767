.make-order__container {
    max-width: 1440px;

}

.make-order__box_card {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 24px;
    padding: 32px 0;
    width: 100%;
    grid-template-rows: auto;
}

.make-order__card {
    max-width: 259.2px;
    width: 100%;
}

.make-order__card__logo-box {
    width: 100%;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #F8F8F8;
}

.make-order__card__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
}

.make-order__card__img {
    max-width: 80px;
    max-height: 100%;
    width: 100%;

}

.make-order__card__decs {
    color: #736B64;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
}

.make-order__card__number {
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-radius: 0px 6px;
    background: #BAA586;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 13px;
    box-sizing: border-box;
    color: #F8F8F8;
}

.make-order__btn {
    width: fit-content;
    border-radius: 6px;
    background: #4F463E;
    padding: 0 13px;
    color: #FFF;
    margin: 0 auto;
    font-size: 18px;
    line-height: 259.259%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:1200px) {
    .make-order__box_card {
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 24px;
    }

    .make-order__card {
        max-width: unset;
        position: relative;
    }
}

@media (max-width:800px) {
    .make-order__title{
        font-size: 32px;
    }
    .make-order__box_card {
        grid-template-columns: 1fr;
        row-gap: 12px;
    }

    .make-order__card {
        max-width: unset;
        display: flex;
        flex-direction: row;
    }

    .make-order__card__logo-box {
        height: 150px;
        width: 35%;
    }

    .make-order__card__text {
        width: 65%;
        background: #F8F8F8;
        margin: 0;
        justify-content: center;
        gap: 12px;
    }
    .make-order__card__decs{
        margin-left: 15px;
    }
}