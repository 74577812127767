.showcase-line-hero {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.showcase-line-hero__title {
    color: var(--neutral-active);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;
    max-width: 100%;
}



.showcase-line-hero__items {
    display: grid;

    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    row-gap: 60px;
    column-gap: 32px;
    width: 100%;
    align-items: center;
    justify-items: center;
}

.showcase-line-hero__items_cards-in-line-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-areas: 'HERO HERO X';
    row-gap: 60px;
    column-gap: 32px;
}

.showcase-line-hero__items_cards-in-line-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-areas: 'HERO HERO X Y';
    row-gap: 60px;
    column-gap: 32px;
}


.showcase-line-hero__items_cards-in-line-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-areas: 'HERO HERO X Y Z';
    row-gap: 60px;
    column-gap: 32px;
}

.showcase-line-hero__items_cards-in-line-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-areas: 'HERO HERO X Y Z G';
    row-gap: 60px;
    column-gap: 32px;
}

@media (max-width: 1000px) {

    .showcase-line-hero__title {
        font-size: var(--h3-size);
    }
}

@media (max-width: 980px) {
    .showcase-line-hero__items_cards-in-line-6 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-template-areas: 'HERO HERO X Y Z';

    }
}

@media (max-width: 900px) {
    .showcase-line-hero__items_cards-in-line-6, .showcase-line-hero__items_cards-in-line-5 {
        grid-template-areas: 'HERO HERO X Y';
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (max-width: 800px) {
    .showcase-line-hero__items_cards-in-line-6, .showcase-line-hero__items_cards-in-line-5, .showcase-line-hero__items_cards-in-line-4 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-areas: 'HERO HERO X';


    }

    .showcase-line-hero__items {
        column-gap: 8px;
        row-gap: 40px;
    }
}

@media (max-width: 600px) {
    .showcase-line-hero__items_cards-in-line-6, .showcase-line-hero__items_cards-in-line-5, .showcase-line-hero__items_cards-in-line-4, .showcase-line-hero__items_cards-in-line-3 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-areas: 'HERO HERO';

    }
}