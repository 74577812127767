.search-card {
    width: 100%;
    display: grid;
    grid-template-columns: 88px 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 12px;
}

.search-card__img {
    width: 100%;
    height: 58px;
    box-sizing: border-box;
    object-fit: scale-down;

}


.search-card__texts {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;
}


.search-card__name {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: var(--neutral-active);
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.search-card__path-box {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 4px;
    margin: 8px 0 0;
}

.search-card__path {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: var(--neutral-active);
    opacity: 0.7;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.search-card__price {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    margin: 0;
    color: var(--neutral-active);
}

.search-card .product-card__image_format_10x16 {
    max-height: 100px;
}

@media (max-width: 1100px) {
    .search-card {
        grid-template-columns: 48px 1fr;
    }

    .search-card__img {
        height: 32px;
        width: 48px;
    }

    .search-card__name {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
    }

    .search-card__path {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
    }

    .search-card__price {
        display: none;
    }
}