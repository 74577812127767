.contact-popup {
  padding: 20px 24px;
}

.contact-popup__close-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
}

.contact-popup__close-icon {
  width: 100%;
  height: 100%;
}

.contact-popup__content {
  width: 100%;
  min-height: 366px;
  max-height: 380px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}


.contact-popup__title {
  color: var(--neutral-active);
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.13;
  padding-top: 12px;
}


.contact-popup__items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 24px 0 0;
  overflow: scroll;
}

.contact-popup__no-items {
  text-align: center;
  width: 100%;
  max-width: 60%;
}

.contact-popup__item{
  width: 100%;
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: max-content;
  column-gap: 20px;
  box-sizing: border-box;
  border-radius: calc(var(--initial_border_radius-value));
  border: 1px solid var(--primary-active);
  padding: 16px 18px;
  align-items: center;
}

.contact-popup__item-icon {
  width: 40px;
  height: 40px;
}

.contact-popup__item-icon-fill {
  fill: var(--neutral-active);
}

.contact-popup__item-icon-bg-fill {

  fill: var(--website_background-background);
}