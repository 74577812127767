.not-found-block {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding: 0 16px;
    box-sizing: border-box;
}

.not-found-block__icon {
    width: 187px;
    height: 187px;
}

.not-found-block__icon-fill {
    fill: var(--secondary-active);
}

.not-found-block__text {

    line-height: 140%;

    text-align: center;
    color: var(--website_background-background);
}

.not-found-block__go-back {
    display: flex;
    width: fit-content;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;

    border-radius: calc(var(--initial_border_radius-value) / (2 * 1));
    background: var(--secondary-active);



    font-size: 14px;
    letter-spacing: -0.7px;
    font-weight: 700;
    color: var(--website_background-background);
    box-sizing: border-box;
}