.promo-check-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    position: relative;
    z-index: 1000;
}

.promo-check-popup__text {
    font-size: var(--text-size);
    text-align: center;
    color: var(--neutral-active);
    font-weight: var(--text-weight);
    line-height: 140%;
}

.promo-check-popup__invalid-icon {
    width: 80px;
    height: 80px;
}

.promo-check-popup__invalid-icon-fill {
    fill: var(--neutral-active);
}

.promo-check-popup__discount {
    font-size: var(--h2-size);
    font-weight: var(--h2-weight);
    line-height: 100%;
    color: var(--neutral-active);
}

.promo-check-popup__text-string {
    font-weight: var(--h2-weight);
}