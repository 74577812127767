.cart {
  width: 100%;
  max-width: 1280px;

  box-sizing: border-box;
  padding: 32px 32px 0;
  position: relative;
}


.cart__title {
  color: var(--neutral-active);

}


.cart__content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-template-rows: max-content;
  column-gap: 32px;
  margin: 32px 0 0;
  /* min-height: 100vh; */
}

.cart__steps {
  /* margin: 32px 0 0; */
  width: 100%;
}

.cart__go-back {
  display: flex;
  width: fit-content;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;

  border-radius: calc(var(--initial_border_radius-value) * (1.50 * 1));

  color: var(--neutral-active);
  box-sizing: border-box;
  margin-bottom: 32px;
  /* right: 32px; */
}

.cart__go-back-text {
  color: var(--neutral-active);
}


@media (max-width: 1200px) {
  .cart__title {

    font-size: 30px;
    top: unset;
    font-weight: 600;
    line-height: 30px;
    margin: 32px 0 0;
    position: relative;
    top: unset;

    letter-spacing: -1.5px;
  }

  .cart__content {
    margin: 32px 0 0;
  }

  .cart__steps {
    margin: 0;
  }

  .cart__go-back {
    margin: 0 0 16px;
  }
}


@media (max-width: 1050px) {
  .cart__content {
    display: flex;
    flex-direction: column;
    gap: 32px;

  }

  .cart__content {
    margin: 16px 0 0;
  }

  .cart__steps {
    margin: 16px 0 0;
  }


}

@media (max-width: 1000px) {
  .cart {
    padding: 0 16px;
  }

  .cart__title {
    margin: 16px 0 0;
  }
}