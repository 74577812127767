.products {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 80px 0 0;
}

.products_with-filters{
    display: grid;
    grid-template-columns: 280px 1fr;
    grid-template-rows: max-content;
    column-gap: 60px;
}

.products__filters {
    position: sticky;
    top: 32px;
    height: fit-content;
    padding: 0 0 32px 0;
    width: 100%;
    margin: 0 24px 0 0;

}


.products__items {
    display: grid;

    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    row-gap: 40px;
    column-gap: 32px;
    width: 100%;
    align-items: center;
    justify-items: center;
}

.products__items-preloader{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.products__items-empty{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    min-height: 50vh;
}

.products__items_cards-in-line-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 80px;
    column-gap: 32px;
}

.products__items_cards-in-line-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 60px;
    column-gap: 32px;
}


.products__items_cards-in-line-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    row-gap: 60px;
    column-gap: 32px;
}

.products__items_cards-in-line-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    row-gap: 60px;
    column-gap: 32px;
}

.products__heading{
    display: none;
}

.products__filters-btn{
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: max-content;
    column-gap: 8px;
    background-color: var(--secondary-selection);
    border-radius: calc(var(--initial_border_radius-value));
    /* border: 1px solid var(--secondary-selection); */
    color: var(--secondary-avtive);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 8px 12px;
}

.products__filters-btn-icon {
    width: 24px;
    height: 24px;
    opacity: 0.7;
}

.products__filters-btn-icon-stroke {
    color: var(--secondary-avtive);
}

@media (max-width: 980px) {
    .products__items_cards-in-line-6 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

@media (max-width: 900px) {
    .products__items_cards-in-line-6, .products__items_cards-in-line-5 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .products_with-filters{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .products__filters{
        display: none;
    }
    .products__heading{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

@media (max-width: 800px) {
    .products__items_cards-in-line-6, .products__items_cards-in-line-5, .products__items_cards-in-line-4 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 8px;
    }

    .products__items {
        row-gap: 40px;
    }
}


@media (max-width: 600px) {
    .products__items_cards-in-line-6, .products__items_cards-in-line-5, .products__items_cards-in-line-4, .products__items_cards-in-line-3 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .products {
        margin: 50px 0 0;
    }
}

/* @media (max-width: 450px) {
    .products__items_cards-in-line-4, .products__items_cards-in-line-3 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
} */