.bool-filter-in-stock {
    width: 100%;

    box-sizing: border-box;
    padding: 12px 0;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: max-content;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
}

.bool-filter-in-stock__name {
    width: 100%;
    color: var(--neutral-active);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.bool-filter-in-stock__separator {
    width: 1px;
    height: 100%;
    background-color: var(--secondary-active);
}

.bool-filter-in-stock__selector {
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
    background-color: var(--secondary-active);
    border-radius: 6px;
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 1px;
}

.bool-filter-in-stock__selector-item {
    transition: all 0.2s ease-in-out;
    border-radius: 6px;
    background-color: transparent;
    color: var(--neutral-active);
}

.bool-filter-in-stock__selector-item_selected {
    background-color: var(--secondary-active);
}

.bool-filter-in-stock__toggle{
    margin: 0 0 0 auto;
}