.range-filter {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 12px 0;
}

.range-filter__heading {
    display: flex;
    flex-direction: row;
    width: 100%;

}

.range-filter__name {
    color: var(--neutral-active);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.range-filter__selector {
    margin: 10px 0 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 6px;
}

.range-filter__selector .MuiSlider-root {
    height: 2px;
    padding: 5px 0;

}

.range-filter__selector .MuiSlider-track {
    border: none;
    color: var(--primary-active);

}

.range-filter__selector .MuiSlider-thumb {
    width: 12px;
    height: 12px;
}


.range-filter__selector .MuiSlider-thumb:hover {
    box-shadow: none !important;
}

.range-filter__selector .Mui-focusVisible {
    box-shadow: none !important;
}

.range-filter__selector .Mui-active {
    box-shadow: none !important;
}

.range-filter__selector .MuiSlider-thumb:hover.Mui-active {
    box-shadow: none !important;
}

.range-filter__selector .MuiSlider-thumb::after {
    display: none;
}

.range-filter__selector .MuiSlider-thumb::before {
    display: none;
}


.range-filter__selector .Mui-active {
    box-shadow: none;
}

.range-filter__selector-values {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 6px 0 0;
}

.range-filter__selector-value {
    color: var(--neutral-active);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
}