.toggle {
    width: 42px;
    height: 24px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 2px;
    background-color: var(--secondary-background);
    transition: all 0.2s ease-in-out;
    position: relative;
    border-radius: 600px;
    border: 1px solid var(--primary-active);
}

.toggle_active {
    background-color: var(--primary-active);
}

.toggle__inside {
    border-radius: 50%;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    background-color: var(--primary-active);

}
.toggle_active .toggle__inside{
    transform: translateX(100%);
    background-color: var(--website_background-background);
}