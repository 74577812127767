.oreder-popup{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px
}

.oreder-popup__icon{
    width: 100px;
    height: 100px;
}

.oreder-popup__icon_fill{
    fill: var(--neutral-active);
}

.oreder-popup__text{
    font-size: var(--text-size);
    text-align: center;
    color: var(--neutral-active);
    font-weight: var(--h3-weight);
    line-height: 140%;
}


.oreder-popup__subtext{
    font-size: var(--text-size);
    text-align: center;
    color: var(--neutral-active);
    font-weight: var(--text-weight);
    line-height: 140%;
}