.catalog {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    padding: 32px 0 0;
    min-height: 100vh;
    padding-bottom: 120px;
}

.catalog__preloader {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.catalog__content {
    width: 100%;
    max-width: 1280px;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.catalog__crumbs {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    overflow: scroll;
}

.catalog__crumb {
    font-size: var(--text-size);
    font-weight: var(--text-weight);
    color: var(--neutral-active);
}

.catalog__category-title {
    color: var(--neutral-active);
    margin: 16px 0 0;
}

.catalog__categories-inside {
    margin: 16px 0 0;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    row-gap: 20px;
    column-gap: 20px;
    width: 100%;
    align-items: center;
    justify-items: center;
}

.catalog__more {
    width: 125px;
    height: 44px;
    margin: 42px auto 0;
    background-color: var(--secondary-active);
    color: var(--website_background-background);
    padding: 14px 16px;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 1));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 8px;
    transition: all 0.2s linear;
}

.catalog__more_loading{
    opacity: 0.7;
    user-select: none;
    pointer-events: none;
}

.catalog__categories-preloader{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (max-width: 1000px) {

    .catalog {
        padding: 16px 0 0;
    }

    .catalog__content {
        padding: 0 16px;
    }

    .catalog__category-title {
        font-size: calc((var(--h2-size))*0.5);
    }
}


@media (max-width: 880px) {
    .catalog__categories-inside {
        grid-template-columns: repeat(4, minMax(0, 1fr));
    }
}

@media (max-width: 600px) {
    .catalog__categories-inside {
        grid-template-columns: repeat(3, minMax(0, 1fr));
    }
}

@media (max-width: 420px) {
    .catalog__categories-inside {
        grid-template-columns: repeat(2, minMax(0, 1fr));
    }
}