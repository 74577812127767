.bool-filter {
    width: 100%;

    box-sizing: border-box;
    padding: 6px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.bool-filter__heading {
    display: flex;
    flex-direction: row;
    width: 100%;

}

.bool-filter__name {
    color: var(--neutral-active);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.bool-filter__separator {
    height: 1px;
    width: 100%;
    background-color: var(--secondary-active);
}

.bool-filter__selector {
    margin: 8px 0 0;
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
    background-color: var(--secondary-selection);
    border-radius: calc(var(--initial_border_radius-value) * (0.50 * 1));
    /* min-height: 100%; */
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 1px;
    align-content: center;
}

.bool-filter__selector-item {
    height: 100%;
    width: 100%;
    padding: 4px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    border-radius: calc(var(--initial_border_radius-value) * (0.50 * 2));
    background-color: transparent;
    color: var(--neutral-active);
}

.bool-filter__selector-item_selected {
    background-color: var(--primary-active);
    color: var(--website_background-background);
}