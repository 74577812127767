.promo-scroll {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    

}

.promo-scroll__slider {
    overflow: hidden;
    width: 100%;
    /* height: 376px; */

}

.promo-scroll__swiper-arrow-btn {
    width: calc(var(--h3-size) * 2);
    height: calc(var(--h3-size) * 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: calc(var(--initial_border_radius-value) * (1.5 * 1));
    background-color: var(--neutral-background);
    
    position: absolute;
    z-index: 2;
}

.promo-scroll__swiper-arrow-btn_left {
    left: 20px;
}

.promo-scroll__swiper-arrow-btn_right {
    right: 20px;
}

.promo-scroll__swiper-arrow-btn_left .promo-scroll__swiper-arrow-icon {
    transform: rotate(180deg);
}

.promo-scroll__swiper-arrow-icon {
    width: var(--h3-size);
    height: var(--h3-size);
}

.promo-scroll__swiper-arrow-icon-fill {
    fill: var(--neutral-active);
}

.promo-scroll__slides {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1));
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}

.promo-scroll__slide {
    /* width: fit-content !important;
    max-width: 100% !important;
    height: 100%; */

    /* background-color: var(--container-bg-color); */
    /* margin-right: 16px; */
    /* position: relative; */
    /* margin-left: 30px; */
    /* display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1)); */
    /* aspect-ratio: 280 / 400; */
    /* width: fit-content !important; */
    width: fit-content !important;
    max-width: 100% !important;
    height: fit-content !important;

    /* max-width: 90%; */

    background-color: var(--container-bg-color);
    /* margin-right: 16px; */
    position: relative;
    /* margin-left: 30px; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
}

/* 
.promo-scroll__slide:last-of-type {
    margin-right: 0;
} */

.promo-scroll__slide-img {
    height: fit-content;
    max-width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
    user-select: none;
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1));

}

.swiper-pagination-bullet {
    background-color: var(--neutral-active) !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--neutral-active) !important;
}

.promo-scroll__slide-title {
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 40px;
    color: var(--neutral-active);
    max-width: calc(100% - 40px * 2);
    width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;
}

@media (max-width: 1000px) {
    .promo-scroll__slider {

    }

    .promo-scroll__slide-title {
        font-size: var(--h3-size);
        left: 20px;
    }
}

@media (max-width: 700px) {
    .promo-scroll__swiper-arrow-btn_left {
        left: 4px;
    }

    .promo-scroll__swiper-arrow-btn_right {
        right: 4px;
    }

    .promo-scroll__swiper-arrow-icon {
        width: calc(var(--h3-size)*0.7);
        height: calc(var(--h3-size)*0.7);
    }

    .promo-scroll__swiper-arrow-btn{
        width: calc(var(--h3-size)*1.3);
        height: calc(var(--h3-size)*1.3);
    }
}