.promo-text-image {
    width: 100%;
    border-radius: calc(var(--initial_border_radius-value) / (2 * 1));
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 54fr 74fr;
    grid-template-areas: 'INFO IMAGE';
    grid-template-rows: 1fr;
    max-height: 800px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}

.promo-text-image_reverse{
    grid-template-areas: 'IMAGE INFO';
    grid-template-columns:  74fr 54fr;
}

.promo-text-image__infos {
    grid-area: INFO;
    display: flex;
    flex-direction: column;
    padding: 20px 50px 40px 40px;
    gap: 20px;
    background-color: var(--neutral-background);
}



.promo-text-image__img {
    grid-area: IMAGE;

    width: 100%;
    /* height: 100%; */
    overflow: hidden;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
}

.promo-text-image__title {
    color: var(--neutral-active);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;
    max-width: 100%;

}

.promo-text-image__text {
    color: var(--neutral-active);
    opacity: 0.8;
    line-height: 130%;
    margin: auto 0 0;
}

.promo-text-image__link {
    margin: auto 0 0;
    width: fit-content;
    max-width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    background-color: var(--primary-active);
    border-radius: calc(var(--initial_border_radius-value) * (1* 1.5));
}

.promo-text-image__link-text {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--website_background-background);
}

@media (max-width: 1000px) {
    .promo-text-image__infos {
        gap: 12px;
        padding: 16px;
    }

    .promo-text-image__title {
        font-size: var(--h3-size);
    }
}


@media (max-width: 700px) {
 .promo-text-image{
    grid-template-columns: unset;
    grid-template-rows: unset;
    display: flex;
    flex-direction: column-reverse;
 }
 .promo-text-image__img{
    max-height: 200px;
 }

}