.address-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
}

.address-input__hints {
    z-index: 2;
    position: absolute;
    top: calc(100% + 8px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    align-items: flex-start;
    background-color: var(--secondary-background);
    border: 1px solid var(--primary-active);
    padding: 4px;
    border-radius: var(--initial_border_radius-value);
    box-sizing: border-box;
    box-shadow: -1px 0px 0px 0px var(--secondary-selection) inset;
}

.address-input__hint {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.48px;
    padding: 8px 8px;
    box-sizing: border-box;
    transition: all 0.1s linear;
    color: var(--text-color);
    text-align: left;
    border-radius: calc(var(--initial_border_radius-value) * 0.5);
    width: 100%;
}

.address-input__hint_selected {
    /* background-color: var(--primary-active); */
    border: 1px solid  var(--primary-active);
}