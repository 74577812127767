.showcase-scroll {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.showcase-scroll__title {
    color: var(--neutral-active);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;
    max-width: 100%;
}


.showcase-scroll__items-slider {
    overflow: hidden;
    width: 100%;
    /* height: 380px; */
}

.showcase-scroll__items {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);

}

.showcase-scroll__item {
    width: fit-content !important;
    max-width: calc(100% / 5) !important;
    padding-bottom: 40px;
    height: 100%;
    /* max-width: 90%; */

    /* margin-right: 16px; */
    position: relative;
    /* margin-left: 30px; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
}

@media (max-width: 1000px) {

    .showcase-scroll__title {
        font-size: var(--h3-size);
    }
}

@media (max-width: 700px) {
    .showcase-scroll__item {
        max-width: calc(100% / 3) !important;
    }
}

@media (max-width: 500px) {
    .showcase-scroll__item {
        max-width: calc(100% / 2) !important;
    }
}